<template>
  <v-row class="app-manager-invoice-preview">
    <v-col cols="12" md="9">
      <v-card v-if="billingData" id="invoice">
        <!-- Header -->
        <v-card-text class="py-9 px-8">
          <div class="invoice-header d-flex flex-wrap justify-space-between flex-column flex-sm-row">
            <!-- Left Content -->
            <div class="mb-8 mb-sm-0">
              <div class="d-flex align-center mb-6">
                <img :src="appLogo" alt="logo" contain class="me-3">
              </div>
            </div>
            <!-- Right Content -->
            <div>
              <span class="d-block">SIA LeadProm Media</span>
              <span class="d-block">Matisa street, 61 - 31, Riga, Latvia, LV-1009</span>
              <span class="d-block">Reg. number 40203230340</span>
              <span class="d-block">VAT LV40203230340</span>
              <span class="d-block">Email: billing@leadprom.com</span>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <!-- Payment Details -->
        <v-card-text class="py-9 px-8">
          <div class="payment-details d-flex justify-space-between flex-wrap flex-column flex-sm-row">
            <div>
              <p class="invoice-number font-weight-medium text-xl text--primary mb-4">
                Invoice #{{ billingData.id.split('-')[0] }}
              </p>
              <p class="invoice-creation-date mb-2">
                <span>Date: </span>
                <span class="font-weight-semibold">{{ billingData.createdAt | moment('YYYY-MM-DD') }}</span>
              </p>
              <p class="invoice-due-date mb-2">
                <span>Due date: </span>
                <span class="font-weight-semibold">{{ billingData.createdAt | moment('add', '7 days', 'YYYY-MM-DD') }}</span>
              </p>
            </div>
          </div>
        </v-card-text>

        <!-- Table -->
        <v-simple-table class="purchased-items-table">
          <template #default>
            <tbody>
              <tr v-for="(total, index) in billingTotals" :key="index" class='mastercard-alert'>
                <td>{{ total.companyName }} Revenue {{ total.period }}</td>
                <td>{{ total.totalAmount.toFixed(2) }} EUR</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!-- Total -->
        <v-card-text class="py-9 px-8">
          <div class="invoice-total d-flex justify-space-between flex-column flex-sm-row">
            <v-spacer></v-spacer>
            <div>
              <v-divider class="mt-4 mb-3"></v-divider>
              <table class="w-full">
                <tr>
                  <td class="pe-16">Total:</td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'invoice-total-in-eur text-right'">{{ billingData.totalAmount }} EUR</th>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" md="3">
      <v-card>
        <v-card-text>
          <v-btn class="billing-list-print-invoice-button mb-3" color="secondary" block outlined @click="printInvoice"> Print </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ref } from '@vue/composition-api';
import store from '@/store';
import router from '@/router';
import { mdiSendOutline, mdiCurrencyUsd } from '@mdi/js';
import themeConfig from '@themeConfig';

export default {
  setup() {
    const billingData = ref(null);
    const billingTotals = ref([]);
    store
      .dispatch('managerBilling/fetchById', { id: router.currentRoute.params.id })
      .then(response => {
        billingData.value = response.data;
        calculateBillingTotals();
      })
      .catch(error => {
        if (error.response.status === 404) {
          billingData.value = undefined;
        }
      });

    const printInvoice = () => {
      window.print();
    };

    const calculateBillingTotals = () => {
      const totalsMap = {};
      billingData.value.merchantBillings.forEach(item => {
        const key = `${item.merchant.id}_${item.period}`;
        if (!totalsMap[key]) {
          totalsMap[key] = {
            companyName: item.merchant.companyName,
            period: item.period,
            totalAmount: parseFloat(item.amount)
          };
        } else {
          totalsMap[key].totalAmount += parseFloat(item.amount);
        }
      });
      billingTotals.value = Object.values(totalsMap);
    };

    return {
      billingData,
      printInvoice,
      appLogo: themeConfig.app.logo,
      icons: {
        mdiSendOutline,
        mdiCurrencyUsd,
      },
      billingTotals,
    };
  },
};
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

@media print {
  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-manager-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-manager-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0 !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-manager-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
